import React, { UseEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import NavHeader from "./components/NavHeader";
import { useEffect } from "react";
import axios from "axios";

const ApplicationRegister = () => {
  const [surname, setSurname] = useState("");
  const [othername, setOthername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [programmeType, setProgrammeType] = useState("");
  const [entryMode, setEntryMode] = useState("");
  const [studyMode, setStudyMode] = useState("");
  const [degreeChoice1, setDegreeChoice1] = useState("");
  const [degreeChoice2, setDegreeChoice2] = useState("");
  const [statementOfMotivation, setStatementOfMotivation] = useState("");
  const [nameOfReligiousInstitution, setNameOfReligiousInstitution] =
    useState("");
  const [addressOfReligiousInstitution, setAddressOfReligiousInstitution] =
    useState("");
  const [countryOfApplication, setCountryOfApplication] = useState("");
  const [data, setData] = useState();
  const [department, setDepartment] = useState([]);
  const [btnText, setBtnText] = useState("Register");
  const [loading, setLoading] = useState(false);

  const codee = phone.slice(0, 3).toString();

  const maxWords = 250;

  const handleStatementOfMotivation = (event) => {
    const input = event.target.value;
    const words = input.trim().split(/\s+/);
    if (words.length <= maxWords) {
      setStatementOfMotivation(input);
    }
  };

  const remainingWords =
    maxWords - statementOfMotivation.trim().split(/\s+/).length;

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const getDepartments = async () => {
    try {
      const { data } = await api.get(
        `/department/all?page_size=100&program=${programmeType}&program_type=${studyMode}`
      );
      setDepartment(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, [programmeType, studyMode]);

  const registerApplicant = async (e) => {
    e.preventDefault();
    if (!programmeType || !entryMode) {
      return toast.info("Please fill all fields", warnProp);
    }
    try {
      setLoading(true);
      setBtnText("Loading...");
      let res = await api.post("/admissions/apply", {
        surname,
        othername,
        email,
        password,
        entryMode,
        programmeType,
        degreeChoice1,
        degreeChoice2,
        statment_of_motivation: statementOfMotivation,
        name_of_religious_institution: nameOfReligiousInstitution,
        address_of_religious_institution: addressOfReligiousInstitution,
        country_of_application: countryOfApplication,
        affliate: "hilton",
      });
      console.log("Result", res);
      let id = res.data.data.application_no;
      setLoading(false);
      setBtnText("Register");
      toast.success("Account Created", successProp);
      navi("/apply/account-created", { replace: true, state: { id } });
    } catch (error) {
      console.log({ error });
      setLoading(false);
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong!",
        errorProp
      );
      setBtnText("Failed, try again!");
    }
  };

  const country = [...new Set(data?.map((item) => item.country))].sort();

  const backgroundStyle = {
    backgroundImage: `url(https://source.unsplash.com/8CqDvPuo_kI)`,
    height: "100% !important",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: "01",
    minHeight: "120vh",
    paddingTop: "1rem",
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <NavHeader />
      <div class="mt-5" style={backgroundStyle}>
        <div
          style={{
            content: "",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        ></div>
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-10 align-self-center">
              <div class="auth-page">
                <div
                  class="card auth-card shadow-lg"
                  style={{ opacity: "0.9" }}
                >
                  <div class="card-body">
                    <div class="px-3">
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">
                          {" "}
                          2023/2024 Hilton Application{" "}
                        </h4>
                      </div>

                      <form
                        autoComplete="false"
                        class="form-horizontal auth-form my-4"
                        onSubmit={registerApplicant}
                      >
                        <div class="form-group">
                          <label for="">Surname</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter surname"
                              value={surname}
                              onChange={(e) => setSurname(e.target.value)}
                              required
                              autoComplete="new-password"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="">Othername</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter othername"
                              value={othername}
                              onChange={(e) => setOthername(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="useremail">Email</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-mail"></i>
                            </span>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="password">Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              autoComplete="new-password"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="mo_number">Programme Type</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={programmeType}
                              onChange={(e) => setProgrammeType(e.target.value)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option>
                                HILTON CIWA-Theological Formation for
                                Women Religious
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="mo_number">Entry Mode</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={entryMode}
                              onChange={(e) => setEntryMode(e.target.value)}
                              required
                            >
                              <option>--Select--</option>
                              <option value="WAEC">WAEC</option>
                              <option value="NECO">NECO</option>
                              <option value="NABTEB">NABTEB</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="mo_number">Study Mode</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-"></i>
                            </span>
                            <select
                              class="form-control"
                              value={studyMode}
                              onChange={(e) => setStudyMode(e.target.value)}
                              required
                            >
                              <option>--Select--</option>
                              <option value="FT">Full time</option>
                            </select>
                          </div>
                        </div>
                        {/* <div class="form-group">
                          <label for="mo_number">First Choice</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={degreeChoice1}
                              onChange={(e) => setDegreeChoice1(e.target.value)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option>
                                HILTON CIWA-Theological Formation for
                                Women Religious
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="mo_number">Second Choice</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={degreeChoice2}
                              onChange={(e) => setDegreeChoice2(e.target.value)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option>
                                HILTON CIWA-Theological Formation for
                                Women Religious
                              </option>
                            </select>
                          </div>
                        </div> */}
                        <div class="form-group">
                          <label for="username">
                            Name of Religious Institution/Congregation
                          </label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter name of religious institution"
                              value={nameOfReligiousInstitution}
                              onChange={(e) =>
                                setNameOfReligiousInstitution(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">
                            Address of Religious Institution/Congregation
                          </label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter address of religious institution"
                              value={addressOfReligiousInstitution}
                              onChange={(e) =>
                                setAddressOfReligiousInstitution(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Country of Application</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <select
                              class="form-control"
                              value={countryOfApplication}
                              onChange={(e) =>
                                setCountryOfApplication(e.target.value)
                              }
                              required
                            >
                              <option value="">--select country--</option>
                              {country.map((items) => (
                                <option value={items} key={items}>
                                  {items}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <div className="w-100 d-flex justify-content-between">
                            <label for="mo_number">
                              Statement of Motivation{" "}
                              <i className="text-danger">
                                (Not more than 250 words)
                              </i>
                            </label>
                            <p>{remainingWords} words remaining</p>
                          </div>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <textarea
                              rows={5}
                              class="form-control"
                              value={statementOfMotivation}
                              onChange={handleStatementOfMotivation}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        </div>
                        <ToastContainer />
                      </form>
                    </div>

                    <div class="m-3 text-center text-muted">
                      <p class="">
                        Already have an account ?{" "}
                        <Link to="/apply/login" class="text-primary ml-2">
                          Log in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationRegister;
