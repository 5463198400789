import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api/api";
import { GetUploadedDocuments } from "../../../api/application";
import { getCurrentUser } from "../../../components/services/auth/authService";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";

const ApplicationUploadDoc = () => {
  const [file, setFile] = useState();
  const [docName, setDocName] = useState("");
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState();

  const address = process.env.REACT_APP_STAGING_URL;

  const { data, staging: stage } = useProspectiveInfo();
  const location = useLocation();

  const inputRef = useRef(null);
  const user = getCurrentUser();

  const handlePassportChange = (e) => {
    setFile(e.target.files[0]);
  };

  const successProp = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const handlePassportUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("doc_path", file);
    formData.append("doc_desc", docName);

    try {
      setLoading(true);
      const { data } = await api.post(
        `/prospectives/document-upload`,
        formData
      );

      console.log(data, "upload");
      toast.success("Document uploaded", successProp);
      setLoading(false);
      setDocName("");
      inputRef.current.value = null;
      window.location.reload();
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const getUploadedDoc = async () => {
    try {
      const data = await GetUploadedDocuments(user?.application_id);
      console.log("uploaded doc", data);
      setDocuments(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUploadedDoc();
  }, []);

  // if (stage === null) {
  //   return (
  //     <Navigate
  //       to={{
  //         pathname: `/apply/payment`,
  //         state: { from: location },
  //       }}
  //       replace
  //     />
  //   );
  // }

  if (stage === "completed" || stage === "rejected" || stage === "approved") {
    return (
      <Navigate
        to={{
          pathname: `/apply`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div class="page-wrapper">
      {/* {data && */}
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active">Documents</li>
                  </ol>
                </div>
                <h4 class="page-title">Upload Documents</h4>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="auth-logo-text">
                    <h4 class="text-danger mb-2">Document Upload Guide.</h4>
                    <hr />
                    <ul class="list-unstyled faq-qa">
                      <li class="mb-4">
                        <h6 class="">
                          1. Select the name of document you want to upload from
                          the dropdown menu
                        </h6>
                      </li>
                      <li class="mb-4">
                        <h6 class="">2. Select the appropriate file</h6>
                      </li>
                      <li class="mb-4">
                        <h6 class="">
                          3. Then click on the upload button and wait until
                          uploaded
                        </h6>
                      </li>
                      <li class="mb-4">
                        <h6 class="">
                          4. Repeat the process until you have uploaded all
                          documents
                        </h6>
                      </li>
                      <br />
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <form onSubmit={handlePassportUpload}>
                        <div class="mb-3">
                          <div className="">
                            <label>Select document title</label>
                            <select
                              class="form-control"
                              value={docName}
                              onChange={(e) => setDocName(e.target.value)}
                            >
                              <option value="">--Select name--</option>
                              <option>WAEC/NECO etc.</option>
                              <option>Signed Signature</option>
                              <option>Passport Photograph</option>
                            </select>
                            <input
                              ref={inputRef}
                              class="form-control"
                              type="file"
                              id="formFile"
                              onChange={handlePassportChange}
                            />
                          </div>
                        </div>
                        <div class="card-body">
                          <button
                            class="btn btn-sm btn-gradient-primary text-white px-4 mb-0"
                            type="submit"
                            disabled={loading}
                          >
                            Upload Document
                          </button>
                        </div>
                      </form>
                    </div>
                    <hr />
                    <div className="col-6">
                      <h6 class="mb-2">Document Uploaded</h6>
                      {documents?.map((document) => (
                        <div class="alert dspg-info" role="alert">
                          {document?.doc_desc}{" "}
                          <a
                            href={address + document?.doc_path}
                            class="alert-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default ApplicationUploadDoc;
