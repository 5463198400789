import img1 from "../../../assets/images/auth_bg.jpeg";
import img2 from "../../../assets/images/rems.jpg";
import { Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import { useState } from "react";

const SliderCarousel = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
    >
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Registration Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul class="list-unstyled">
            <li class="mb-4">
              <h6>1. We advise you register with a valid email address</h6>
            </li>
            <li class="mb-4">
              <h6>
                2. You will provide your names and courses of choice (1st Choice
                and 2nd Choice)
              </h6>
            </li>
            <li class="mb-4">
              <h6 class="">3. Select programme type i.e HND or ND</h6>
            </li>
            <li class="mb-4">
              <h6 class="">4. Select study mode i.e Part-time or Full-time</h6>
            </li>
            <li class="mb-4">
              <h6>5. Select Entry mode i.e Direct Entry or UTME</h6>
            </li>
            <li class="mb-4">
              <h6>6. After Registration, check your email for your password</h6>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <h1 className="text-uppercase black font-weight-bold dspg-outline text-center">
            Welcome
          </h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="black text-center">
              As you work hard to achieve your educational goals,
              <br />
              remember that it is all worth it - because you are earning a
              Professional Certification that can open doors for
              you in the future
            </h4>
          </div>
        </div>
        <div className="carousel-item">
          <h2 className="text-uppercase black font-weight-bold dspg-outline text-center">
            Admission Requirements
          </h2>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h5 className="black text-center">
              A. Completed application form <br />
              B. Copy of Passport/National Identity Document
              <br />
              C. Copies of Secondary and all post-secondary education
              <br />
              certificates where applicable
              <br />
              D. Statement of motivation 250-300 words explaining
              <br />
              your motivation to take this course
              <br />
              E. Readiness to participate in a monitoring and evaluation
              <br />
              process during studies and after graduation
              <br />
              F. If you are a major superior: A letter from your major religious
              superior, missioning
              <br />
              you to take the course for a maximum duration of one year and
              demonstrating that you have
              <br />
              full support of the superior for the duration of this course.
            </h5>
          </div>
        </div>
        <div className="carousel-item">
          <h1 className="text-uppercase black font-weight-bold dspg-outline text-center">
            Apply for admission
          </h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h5 className="black text-center">
              Application for theology programme for Catholic Sisters across
              Africa
            </h5>
            <h6 className="font-weight-normal text-danger">-</h6>
            <Link to="apply/register">
              <button className="mt-5 w-20 btn btn-grad waves-effect">
                Click to Begin
              </button>
            </Link>
          </div>
        </div>

        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default SliderCarousel;
