import axios from "axios";
import api from "../../../api/api";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { Country, State, City } from "country-state-city";
import Footer from "../../../components/Footer";
import {
  GetApplicantDetails,
  GetPaymentStatus,
} from "../../../api/application";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import useDetails from "../../../hooks/useDetails";
import avatar from "../../../assets/images/user.png";
import GuardianTab from "../components/GuardianTab";
import WorkExperience from "../components/WorkExperience";
import SchoolAttended from "../components/SchoolAttended";
import Referees from "../components/Referees";

function ApplicationForm() {
  const [details, setDetails] = useState();
  const [file, setFile] = useState();
  const [bio, setBio] = useState();
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(data?.country_origin);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState(data?.state_origin);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(data?.lga_origin);
  const [title, setTitle] = useState(data?.title);
  const [surname, setSurname] = useState(data?.surname);
  const [othername, setOthername] = useState(data?.othername);
  const [username, setUsername] = useState(data?.username);
  const [passport, setPassport] = useState(data?.photo);
  const [dob, setDob] = useState(data?.dob);
  const [mob, setMob] = useState(data?.mob);
  const [yob, setYob] = useState(data?.yob);
  const [religion, setReligion] = useState(data?.religion);
  const [maritalStatus, setMaritalStatus] = useState(data?.marital_status);
  const [nameOfReligiousInstitution, setNameOfReligiousInstitution] =
    useState();
  const [addressOfReligiousInstitution, setAddressOfReligiousInstitution] =
    useState();
  const [countryOfApplication, setCountryOfApplication] = useState();
  const [statementOfMotivation, setStatementOfMotivation] = useState()

  const [nokTitle, setNokTitle] = useState("");
  const [nokEmail, setNokEmail] = useState("");
  const [nokSurname, setNokSurname] = useState("");
  const [nokOthername, setNokOthername] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nokAddress, setNokAddress] = useState("");
  const [nokPhone, setNokPhone] = useState("");

  const [nokCountry, setNokCountry] = useState("");
  const [nokState, setNokState] = useState("");
  const [nokCity, setNokCity] = useState("");

  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const navi = useNavigate();

  const user = getCurrentUser();

  const location = useLocation();

  const { staging: stage, data: detailsData } = useDetails();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const updateBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/update/${user.application_id}`,
        {
          email: email,
          phone: phone,
          surname,
          othername,
          username,
          title,
          dob,
          mob,
          yob,
          country_origin: selectedCountry,
          state_origin: selectedState,
          lga_origin: selectedCity,
          gender,
          religion,
          marital_status: maritalStatus,
          name_of_religious_institution: nameOfReligiousInstitution,
          address_of_religious_institution: addressOfReligiousInstitution,
          country_of_application: countryOfApplication,
          statment_of_motivation: statementOfMotivation
        }
      );
      console.log(res);
      toast.success("BioData updated", successProp);
      getDetails();
      navi("/apply");
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const updateNokBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/nok/update/${user.application_id}/`,
        {
          email: nokEmail,
          phone: nokPhone,
          surname: nokSurname,
          othername: nokOthername,
          title: nokTitle,
          country: nokCountry,
          state: nokState,
          city: nokCity,
          address: nokAddress,
        }
      );
      console.log(res);
      toast.success("Next of kin biodata updated", successProp);
      getDetails();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const handleSelectGender = (event) => {
    console.log(event.target.value);
    setGender(event.target.value);
  };

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const country = [...new Set(data.map((item) => item.country))].sort();

  const handleCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setSelectedCountry(coun);
      console.log("Selected Country is", coun);
      let states = NaijaStates.states();
      console.log("The states noww", states);
      setState(states);
    } else {
      setSelectedCountry(coun);
      console.log("Selected Country is", coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleState = (e) => {
    let st = e.target.value;

    if (selectedCountry === "Nigeria") {
      setSelectedState(st);
      console.log("Selected state is", st);
      let strValue = st.toString();
      console.log("String", strValue);
      const dCities = NaijaStates.lgas(strValue);
      console.log("dCities", dCities);
      setCities(dCities.lgas);
    } else {
      setSelectedState(st);
      console.log("Selected state is", st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      console.log(cities);
      setCities(cities);
    }
  };
  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const handleCity = (e) => {
    let ct = e.target.value;
    console.log(ct);
    setSelectedCity(ct);
  };

  // Nok of kin country, state and city handler

  const handleNokCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setNokCountry(coun);
      console.log("Selected Country is", coun);
      let states = NaijaStates.states();
      console.log("The states noww", states);
      setState(states);
    } else {
      setNokCountry(coun);
      console.log("Selected Country is", coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleNokState = (e) => {
    let st = e.target.value;

    if (nokCountry === "Nigeria") {
      setNokState(st);
      console.log("Selected state is", st);
      let strValue = st.toString();
      console.log("String", strValue);
      const dCities = NaijaStates.lgas(strValue);
      console.log("dCities", dCities);
      setCities(dCities.lgas);
    } else {
      setNokState(st);
      console.log("Selected state is", st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      console.log(cities);
      setCities(cities);
    }
  };

  const handleNokCity = (e) => {
    let ct = e.target.value;
    console.log(ct);
    setNokCity(ct);
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const { data } = await GetApplicantDetails(user.application_id);
      console.log("Details", data);
      setPassport(data.photo);
      setTitle(data.title);
      setSurname(data.surname);
      setOthername(data.othername);
      setEmail(data.email);
      setPhone(data.phone);
      setUsername(data.username);
      setGender(data.gender);
      setSelectedCity(data.lga_origin);
      setSelectedState(data.state_origin);
      setSelectedCountry(data.country_origin);
      setDob(data.dob);
      setMob(data.mob);
      setYob(data.yob);
      setNameOfReligiousInstitution(data.name_of_religious_institution);
      setAddressOfReligiousInstitution(data.address_of_religious_institution)
      setCountryOfApplication(data.country_of_application)
      setStatementOfMotivation(data.statment_of_motivation)
      setReligion(data.religion);
      setMaritalStatus(data.marital_status);
      setNokSurname(data?.applicant_nok[0].surname);
      setNokTitle(data?.applicant_nok[0].title);
      setNokOthername(data?.applicant_nok[0].othername);
      setNokEmail(data?.applicant_nok[0].email);
      setNokAddress(data?.applicant_nok[0].address);
      setNokCity(data?.applicant_nok[0].city);
      setNokState(data?.applicant_nok[0].state);
      setNokPhone(data?.applicant_nok[0].phone);
      setNokCountry(data.applicant_nok[0].country);
      setLoading(false);
      setDetails(data);
    } catch (error) {
      setLoading(false);
    }
  };
  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const handlePassportChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePassportUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("photo", file);

    try {
      setLoading(true);
      const { data } = await api.patch(
        `/admissions/applicant/upload-photo/${user.application_id}`,
        formData
      );

      console.log(data, "passphoto");
      setPassport(data.photo);
      toast.success("Passport upload successful");
      window.location.reload();
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getDetails();
    fetchPaymentStatus();
  }, [passport]);

  // if (stage === null) {
  //   return (
  //     <Navigate
  //       to={{
  //         pathname: `/apply/payment`,
  //         state: { from: location },
  //       }}
  //       replace
  //     />
  //   );
  // }
  if (stage === "completed" || stage === "rejected" || stage === "approved") {
    return (
      <Navigate
        to={{
          pathname: `/apply`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div class="page-wrapper">
      {detailsData && (
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                      <li class="breadcrumb-item active">Profile</li>
                    </ol>
                  </div>
                  <h4 class="page-title">Profile</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body  met-pro-bg">
                    <div class="met-profile">
                      <div class="row">
                        <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                          <div class="met-profile-main">
                            <div class="met-profile-main-pic">
                              <img
                                src={passport}
                                onError={onImageError}
                                alt=""
                                className="rounded-circle"
                                style={{
                                  height: "100px",
                                  objectFit: "contain",
                                }}
                              />
                              <span class="fro-profile_main-pic-change">
                                <i class="fas fa-camera"></i>
                              </span>
                            </div>
                            <div class="met-profile_user-detail">
                              <h5 class="met-user-name">
                                {details
                                  ? details?.surname + " " + details?.othername
                                  : ""}
                              </h5>
                              <p class="mb-0 met-user-name-post">Applicant</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 ml-auto">
                          <ul class="list-unstyled personal-detail">
                            <li class="">
                              <i class="dripicons-phone mr-2 text-info font-18"></i>{" "}
                              <b> phone </b> : {details ? details?.phone : ""}
                            </li>
                            <li class="mt-2">
                              <i class="dripicons-mail text-info font-18 mt-2 mr-2"></i>{" "}
                              <b> Email </b> : {details ? details?.email : ""}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <ul
                      class="nav nav-pills mb-0"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="general_detail_tab"
                          data-toggle="pill"
                          href="#general_detail"
                        >
                          Profile
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="activity_detail_tab"
                          data-toggle="pill"
                          href="#activity_detail"
                        >
                          Next of kin
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="guardian_detail_tab"
                          data-toggle="pill"
                          href="#guardian_detail"
                        >
                          Guardian
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="school_detail_tab"
                          data-toggle="pill"
                          href="#school_detail"
                        >
                          School Attended
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="work_detail_tab"
                          data-toggle="pill"
                          href="#work_detail"
                        >
                          Work Experience
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="referees_detail_tab"
                          data-toggle="pill"
                          href="#referees_detail"
                        >
                          Referees
                        </a>
                      </li>
                      {/* <li class="nav-item">
                                <a class="nav-link" id="portfolio_detail_tab" data-toggle="pill" href="#portfolio_detail">Portfolio</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="settings_detail_tab" data-toggle="pill" href="#settings_detail">Settings</a>
                            </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-9 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <form
                              onSubmit={handlePassportUpload}
                              class="card-box"
                            >
                              <div class="col-xl-6">
                                <div class="card-body">
                                  <h4 class="mt-0 header-title">
                                    Passport Upload
                                  </h4>
                                  <input
                                    type="file"
                                    id="input-file-now"
                                    onChange={handlePassportChange}
                                    className="dropify"
                                    disabled={loading}
                                  />
                                </div>
                                <div class="card-body">
                                  <button
                                    class="btn btn-sm btn-gradient-primary text-white px-4 mb-0"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    Upload Passport
                                  </button>
                                </div>
                              </div>
                            </form>

                            <div class="">
                              <form
                                class="form-horizontal form-material mb-0"
                                onSubmit={updateBio}
                              >
                                <div class="form-group row">
                                  <div className="col-md-2">
                                    <label>Title</label>
                                    <select
                                      class="form-control"
                                      value={title}
                                      onChange={(e) => setTitle(e.target.value)}
                                    >
                                      <option>
                                        {title ? title : "--Title--"}
                                      </option>
                                      <option>Rev.Sr</option>
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Surname</label>
                                    <input
                                      type="text"
                                      placeholder={detailsData?.surname}
                                      class="form-control"
                                      value={surname}
                                      onChange={(e) =>
                                        setSurname(e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                  <div class="col-md-6">
                                    <label>Other names</label>
                                    <input
                                      type="text"
                                      placeholder={detailsData?.othername}
                                      class="form-control"
                                      value={othername}
                                      onChange={(e) =>
                                        setOthername(e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-md-4">
                                    <label>Email</label>
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      class="form-control"
                                      name="example-email"
                                      id="example-email"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>Phone Number</label>
                                    <input
                                      type="text"
                                      placeholder="Phone number"
                                      class="form-control"
                                      value={phone}
                                      onChange={(e) => setPhone(e.target.value)}
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>Username</label>
                                    <input
                                      type="text"
                                      placeholder="Username"
                                      class="form-control"
                                      value={username}
                                      onChange={(e) =>
                                        setUsername(e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-md-12">
                                    <label>Gender</label>
                                    <select
                                      class="form-control"
                                      value={gender}
                                      onChange={handleSelectGender}
                                    >
                                      <option>--Gender--</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-md-6">
                                    <label>Marital Status</label>
                                    <select
                                      class="form-control"
                                      value={maritalStatus}
                                      onChange={(e) =>
                                        setMaritalStatus(e.target.value)
                                      }
                                    >
                                      <option>--Select--</option>
                                      <option>Single</option>
                                      <option>Married</option>
                                      <option>Seperated</option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <label>Religion</label>
                                    <select
                                      class="form-control"
                                      value={religion}
                                      onChange={(e) =>
                                        setReligion(e.target.value)
                                      }
                                    >
                                      <option>--Select--</option>
                                      <option>Christianity</option>
                                      <option>Islam</option>
                                      <option>Others</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-md-4">
                                    <label>Day of Birth</label>
                                    <input
                                      type="number"
                                      placeholder="Day of birth"
                                      class="form-control"
                                      value={dob}
                                      onChange={(e) => setDob(e.target.value)}
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>Month of Birth</label>
                                    <input
                                      type="number"
                                      placeholder="Month of birth"
                                      class="form-control"
                                      value={mob}
                                      onChange={(e) => setMob(e.target.value)}
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>Year of Birth</label>
                                    <input
                                      type="number"
                                      placeholder="Year of birth"
                                      class="form-control"
                                      value={yob}
                                      onChange={(e) => setYob(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-md-4">
                                    <label>Country of Origin</label>
                                    <select
                                      class="form-control"
                                      onChange={handleCountry}
                                    >
                                      <option value="">
                                        {selectedCountry
                                          ? selectedCountry
                                          : "--Country--"}
                                      </option>
                                      {country.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>State of Origin</label>
                                    <select
                                      class="form-control"
                                      onChange={handleState}
                                    >
                                      <option value="">
                                        {selectedState
                                          ? selectedState
                                          : "--State--"}
                                      </option>
                                      {state.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Local govt area</label>
                                    <select
                                      class="form-control"
                                      onChange={handleCity}
                                    >
                                      <option value="">
                                        {selectedCity
                                          ? selectedCity
                                          : "--LGA--"}
                                      </option>
                                      {cities.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-md-4">
                                    <label>Name of Religious Institution</label>
                                    <input
                                      class="form-control"
                                      value={nameOfReligiousInstitution}
                                      onChange={(e) =>
                                        setNameOfReligiousInstitution(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>
                                      Address of Religious Institution
                                    </label>
                                    <input
                                      class="form-control"
                                      value={addressOfReligiousInstitution}
                                      onChange={(e) =>
                                        setAddressOfReligiousInstitution(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div class="col-md-4">
                                    <label>Country of Application</label>
                                    <select
                                      class="form-control"
                                      value={countryOfApplication}
                                      onChange={(e)=> setCountryOfApplication(e.target.value)}
                                    >
                                      <option value="">
                                      </option>
                                      {country.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group">
                                  {/* <label>Residential Address</label>
                                <textarea
                                  rows="5"
                                  placeholder="Residential address"
                                  class="form-control"
                                ></textarea> */}
                                  <button
                                    class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                                    type="submit"
                                    disabled={loading ? true : false}
                                  >
                                    Update Profile
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="activity_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-9 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <div class="">
                              <form
                                class="form-horizontal form-material mb-0"
                                onSubmit={updateNokBio}
                              >
                                <div class="form-group row">
                                  <div className="col-md-2">
                                    <label>Next of kin Title</label>
                                    <select
                                      class="form-control"
                                      value={nokTitle}
                                      onChange={(e) =>
                                        setNokTitle(e.target.value)
                                      }
                                    >
                                      <option>
                                        {nokTitle ? nokTitle : "--Title--"}
                                      </option>
                                      <option>Rev.Fr</option>
                                      <option>Mr</option>
                                      <option>Rev</option>
                                      <option>Rev.Sr</option>
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Next of kin Surname</label>
                                    <input
                                      type="text"
                                      placeholder="Surname"
                                      class="form-control"
                                      value={nokSurname}
                                      onChange={(e) =>
                                        setNokSurname(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div class="col-md-6">
                                    <label>Next of kin Other names</label>
                                    <input
                                      type="text"
                                      placeholder="Othername"
                                      class="form-control"
                                      value={nokOthername}
                                      onChange={(e) =>
                                        setNokOthername(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-md-6">
                                    <label>Next of kin Email</label>
                                    <input
                                      type="email"
                                      placeholder="Email"
                                      class="form-control"
                                      name="example-email"
                                      id="example-email"
                                      value={nokEmail}
                                      onChange={(e) =>
                                        setNokEmail(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                  <div class="col-md-6">
                                    <label>Next of kin Phone Number</label>
                                    <input
                                      type="text"
                                      placeholder="Phone number"
                                      class="form-control"
                                      value={nokPhone}
                                      onChange={(e) =>
                                        setNokPhone(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="form-group row"></div>
                                <div class="form-group row">
                                  <div class="col-md-4">
                                    <label>Next of kin Country of Origin</label>
                                    <select
                                      class="form-control"
                                      onChange={handleNokCountry}
                                    >
                                      <option value="">
                                        {nokCountry
                                          ? nokCountry
                                          : "--Country--"}
                                      </option>
                                      {country.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>NOK State of Origin</label>
                                    <select
                                      class="form-control"
                                      onChange={handleNokState}
                                    >
                                      <option value="">
                                        {nokState ? nokState : "--State--"}
                                      </option>
                                      {state.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <label>Next of kin Local govt area</label>
                                    <select
                                      class="form-control"
                                      onChange={handleNokCity}
                                    >
                                      <option value="">
                                        {nokCity ? nokCity : "--LGA--"}
                                      </option>
                                      {cities.map((items) => (
                                        <option value={items} key={items}>
                                          {items}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Next of kin Residential Address</label>
                                  <textarea
                                    rows="5"
                                    placeholder="Residential address"
                                    class="form-control"
                                    value={nokAddress}
                                    onChange={(e) =>
                                      setNokAddress(e.target.value)
                                    }
                                  />
                                  <button
                                    class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    Update Next of kin Profile
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Guardian */}
                  <div class="tab-pane fade" id="guardian_detail">
                    <GuardianTab />
                  </div>
                  <div class="tab-pane fade" id="school_detail">
                    <SchoolAttended />
                  </div>
                  <div class="tab-pane fade" id="work_detail">
                    <WorkExperience />
                  </div>
                  <div class="tab-pane fade" id="referees_detail">
                    <Referees />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal modal-rightbar fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="MetricaRightbar"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title mt-0" id="MetricaRightbar">
                    Appearance
                  </h5>
                  <button
                    type="button"
                    class="btn btn-sm btn-soft-primary btn-circle btn-square"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <i class="mdi mdi-close"></i>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    class="nav nav-pills nav-justified mt-2 mb-4"
                    role="tablist"
                  >
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#ActivityTab"
                        role="tab"
                      >
                        Activity
                      </a>
                    </li>
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#TasksTab"
                        role="tab"
                      >
                        Tasks
                      </a>
                    </li>
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#SettingsTab"
                        role="tab"
                      >
                        Settings
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div
                      class="tab-pane active "
                      id="ActivityTab"
                      role="tabpanel"
                    >
                      <div class="bg-light mx-n3">
                        <img
                          src="../assets/images/small/img-1.gif"
                          alt=""
                          class="d-block mx-auto my-4"
                          height="180"
                        />
                      </div>
                      <div class="slimscroll scroll-rightbar">
                        <div class="activity">
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  10 Min ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task finished
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>

                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-timer-off bg-soft-pink"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  50 Min ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task Overdue
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                              <span class="badge badge-soft-secondary">
                                Design
                              </span>
                              <span class="badge badge-soft-secondary">
                                HTML
                              </span>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-alert-decagram bg-soft-purple"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  10 hours ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Task
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>

                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-clipboard-alert bg-soft-warning"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  yesterday
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Comment
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-clipboard-alert bg-soft-secondary"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  01 feb 2020
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Lead Meting
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  26 jan 2020
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task finished
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="TasksTab" role="tabpanel">
                      <div class="m-0">
                        <div id="rightbar_chart" class="apex-charts"></div>
                      </div>
                      <div class="text-center mt-n2 mb-2">
                        <button type="button" class="btn btn-soft-primary">
                          Create Project
                        </button>
                        <button type="button" class="btn btn-soft-primary">
                          Create Task
                        </button>
                      </div>
                      <div class="slimscroll scroll-rightbar">
                        <div class="p-2">
                          <div class="media mb-3">
                            <img
                              src="../assets/images/widgets/project3.jpg"
                              alt=""
                              class="thumb-lg rounded-circle"
                            />
                            <div class="media-body align-self-center text-truncate ml-3">
                              <p class="text-success font-weight-semibold mb-0 font-14">
                                Project
                              </p>
                              <h4 class="mt-0 mb-0 font-weight-semibold text-dark font-18">
                                Payment App
                              </h4>
                            </div>
                          </div>
                          <span>
                            <b>Deadline:</b> 02 June 2020
                          </span>
                          <a href="javascript: void(0);" class="d-block mt-3">
                            <p class="text-muted mb-0">
                              Complete Tasks<span class="float-right">75%</span>
                            </p>
                            <div
                              class="progress mt-2"
                              style={{ height: "4px" }}
                            >
                              <div
                                class="progress-bar bg-secondary"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </a>
                        </div>
                        <hr class="hr-dashed" />
                      </div>
                    </div>
                    <div class="tab-pane" id="SettingsTab" role="tabpanel">
                      <div class="p-1 bg-light mx-n3">
                        <h6 class="px-3">Account Settings</h6>
                      </div>
                      <div class="p-2 text-left mt-3">
                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch1"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch1"
                          >
                            Auto updates
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch2"
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch2"
                          >
                            Location Permission
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch3"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch3"
                          >
                            Show offline Contacts
                          </label>
                        </div>
                      </div>
                      <div class="p-1 bg-light mx-n3">
                        <h6 class="px-3">General Settings</h6>
                      </div>
                      <div class="p-2 text-left mt-3">
                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch4"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch4"
                          >
                            Show me Online
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch5"
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch5"
                          >
                            Status visible to all
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch6"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch6"
                          >
                            Notifications Popup
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default ApplicationForm;
